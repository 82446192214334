@value variables: "~modules/ui/variables.css";
@value roboto-font, xs-breakpoint from variables;

.textCommon {
  font-family: roboto-font;
  font-style: normal;
  color: #4f4f4f;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.textBold {
  font-weight: bold;
}

.textSmall {
  font-size: 12px;
}

.textLight {
  color: #828282;
}

.textLarge {
  font-size: 18px;
}

.textWarning {
  color: #eb5757;
}

.cardWrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.tripDetailsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tripInfoWrapper {
  margin-bottom: 13px;
}

.icon {
  margin-top: 3px;
}

.paddingBottom {
  padding-bottom: 15px;
}

.paddingTop {
  padding-top: 5px;
}

.tripReserveWrapper {
  margin-top: 35px !important;
}

@media (max-width: xs-breakpoint) {
  .cardWrapper {
    flex-wrap: wrap;
    justify-content: center;
  }

  .tripReserveWrapper {
    margin-top: 10px !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}
