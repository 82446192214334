.container {
  padding: 50px;
}

.closeIcon {
  position: absolute;
  top: 6px;
  right: 6px;
}

.contentContainer {
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: 100%;
  row-gap: 1rem;
  place-items: center;
}

.termLink {
  margin-top: 2rem;
}

.title {
  text-align: center;
  margin-bottom: 1rem;
}
