@value variables: "~modules/ui/variables.css";
@value roboto-font from variables;
html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: white;
  overflow-x: hidden;
  font-family: roboto-font;
}

.app {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  font-family: roboto-font;
  position: relative;
}

.app a {
  text-decoration: none;
}

:global(#root) {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
