@value variables: "~modules/ui/variables.css";
@value md-breakpoint, xs-breakpoint, limed-spruce from variables;

.dateWrap {
  margin-bottom: 15px;
}

.fontNormal {
  font-size: 14px;
}

.cartWrapper {
  max-width: 271px;
  min-width: 231px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  font-family: Roboto;
}

.cartTitle {
  font-size: 20px;
  padding-bottom: 18px;
  margin-top: 11px;
  padding-left: 9px;
  font-weight: 500;
  color: #4f4f4f;
  border-bottom: 1px solid #e0e0e0;
}

.cartContent {
  font-size: 12px;
}

.ticketWrapper {
  padding: 14px;
  padding-top: 0px;
}

.emptyWrapper {
  padding: 11px 16px 16px 16px;
}

.buttonContainer {
  border-top: 1px solid #e0e0e0;
  padding: 15px;
}

.contentFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.marginRight {
  margin-right: 31px;
}

.price {
  margin: 22px 0px 6px 0px;
  text-align: end;
}

.fullWidth {
  width: 100%;
}

.justifyCenter {
  justify-content: center;
}

.infoContainer {
  margin: 12px 0px;
}

.textLight {
  color: #828282;
}

.iconWrapper {
  margin-right: 10px;
}

.tripInfoMobileWrapper {
  display: flex;
  align-items: center;
}

.tripInfoMobileCity {
  margin: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: limed-spruce;
}

.tripInfoMobileArrow {
  font-size: 1.1rem !important;
  padding: 0 5px;
}

@media (max-width: md-breakpoint) {
  .cartWrapper {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: xs-breakpoint) {
  .cartTitle {
    display: none;
  }

  .dateWrap {
    margin-bottom: 0;
  }

  .infoContainer {
    margin-top: 0;
  }

  .cartWrapper {
    border-top-width: 0px;
  }

  .cartContent {
    margin-top: -10px;
  }
}
